// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
const firebaseConfig = {
  apiKey: "AIzaSyCJ28jUsk5HlBscwMyNwqwP5N3AAUUrito",
  authDomain: "yvomvk1app.firebaseapp.com",
  databaseURL: "https://yvomvk1app-default-rtdb.firebaseio.com",
  projectId: "yvomvk1app",
  storageBucket: "yvomvk1app.appspot.com",
  messagingSenderId: "856969441217",
  appId: "1:856969441217:web:1e6babb46bfc8784188940",
  measurementId: "G-B0T9HB38Y7"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export { app };